import { get } from 'lodash';
import validator from './validate';

const switchProcessor = {
    name: 'Switch',
    slug: 'switch',
    description: 'Split your workflow into different paths',
    validate: validator,
    additionalProperties: false,
    inputs: [
        {
            id: 'input',
            name: 'Input',
            schema: {
                oneOf: [
                    { type: 'number' },
                    { type: 'string' },
                    { type: 'boolean' },
                ],
            },
        },
    ],
    outputs(node: {
        configuration: {
            conditons: Record<'label', string>[];
            catchAll?: string;
        };
    }): { id: string; name: string; schema: { type: 'string' } } {
        const outputs = get(node, 'configuration.conditions', []).map(
            (condition: { label?: string }, i: number) => ({
                id: i.toString(),
                name: condition.label || '',
                schema: { type: 'string' },
            }),
        );

        const catchAll = get(node, 'configuration.catchAll', undefined);
        if (catchAll !== undefined) {
            outputs.push({
                id: 'catchAll',
                name: catchAll,
                schema: { type: 'string' },
            });
        }

        return outputs;
    },
};

export default switchProcessor;
