import { get } from 'lodash';
import { getEdgesForNode } from '../io';
import { VALIDATION_CODES } from '../../validation/validationCodes';
import type { Blueprint } from '../types';

const dataRobotValidator = (blueprint: Blueprint, nodeId: string) => {
    const node = get(blueprint, `nodes["${nodeId}"]`);

    const mapping = get(node, 'configuration.mapping', []);

    const inputEdges = getEdgesForNode({
        nodeId,
        blueprint,
        incoming: true,
        outgoing: false,
        conditions: false,
    });

    if (mapping.length < inputEdges.length) {
        return [
            {
                id: nodeId,
                code: VALIDATION_CODES.NODE_CONFIGURATION_ERROR,
                message: `Some inputs of "${node.name}" block have no attached mapping.`,
            },
        ];
    }

    if (mapping.length > inputEdges.length) {
        return [
            {
                id: nodeId,
                code: VALIDATION_CODES.NODE_CONFIGURATION_ERROR,
                message: `Some mapped inputs of "${node.name}" block are missing.`,
            },
        ];
    }

    return [];
};

export default dataRobotValidator;
